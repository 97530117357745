import { useState } from "preact/hooks";
import { showFile } from "../utils/file.ts";
import {
  CrossIcon,
  DecrementBtn,
  DeleteBtn,
  IncrementBtn,
} from "../components/SVGs.tsx";
import { AlertToast, ErrorToast } from "../utils/helper.ts";

const POP = (
  { handleClose, setNotificationType, setShowNotification, setMessage }: any,
) => {
  const [availableCartItems, setavailableCartItems] = useState(
    JSON.parse(localStorage.getItem("cart") || "[]"),
  );

  const incrementQuantity = (id: string) => {
    const updateData = availableCartItems.map((obj: any) => {
      if (obj.id === id) {
        return {
          ...obj,
          price: Number(obj.price) + Number(obj.variants.price),
          quantity: obj.quantity + 1,
        };
      }
      return obj;
    });
    setavailableCartItems(updateData);
    localStorage.setItem("cart", JSON.stringify(updateData));
  };

  const decrementQuantity = (id: string) => {
    const updateData = availableCartItems.map((obj: any) => {
      if (obj.id === id && obj.quantity >= 1) {
        return {
          ...obj,
          price: Number(obj.price) - Number(obj.variants.price),
          quantity: obj.quantity - 1,
        };
      }
      return obj;
    });
    setavailableCartItems(updateData);
    localStorage.setItem("cart", JSON.stringify(updateData));
  };

  const deleteCartItem = (id: string) => {
    const updateData = availableCartItems.filter((obj: any) => obj.id !== id);
    setavailableCartItems(updateData);
    localStorage.setItem("cart", JSON.stringify(updateData));
    // setNotificationType("info");
    // setShowNotification(true);
    // setMessage("Product deleted successfully!");
    AlertToast("Produkten har tagits bort från korgen!");
  };

  return (
    <>
      <div className="popup">
        <div className="popup-content">
          <div
            class="mt-1 p-2 flex justify-end mr-2 cursor-pointer"
            onClick={handleClose}
          >
            <CrossIcon />
          </div>
          <section class="relative">
            <div className="w-[750px]  px-3 md:px-5 lg-6 mx-auto ">
              <div class="hidden lg:grid grid-cols-2 py-[8px] gap-x-[35px] border-b border-[#212529]">
                <div class="pl-2 font-[600] text-xl leading-8 cart-fonts text-[#212529] ">
                  Produkt
                </div>
                <p class="text-xl leading-8  flex items-center justify-between ">
                  <span class="w-full max-w-[260px] text-start cart-fonts font-[600]">
                    Antal
                  </span>
                  <span class="w-full max-w-[200px] text-center cart-fonts font-[600]">
                    Pris
                  </span>
                  <span class="w-full max-w-[175px] text-center cart-fonts font-[600]">
                    Radera
                  </span>
                </p>
              </div>
              <div
                class={`max-h-[567px] ${
                  availableCartItems.length > 2 ? "overflow-y-scroll" : ""
                } `}
              >
                {Array.isArray(availableCartItems) &&
                  availableCartItems.map((item: any) => {
                    return (
                      <>
                        <div
                          class={`grid grid-cols-1 lg:grid-cols-2 min-[550px]:gap-6 ${
                            availableCartItems.length > 1
                              ? "border-b border-[#ccc]"
                              : ""
                          }  py-3`}
                        >
                          <div class="flex items-center flex-col min-[550px]:flex-row gap-3 min-[550px]:gap-6 w-full max-xl:justify-center max-xl:max-w-xl max-xl:mx-auto">
                            <div class="img-box">
                              <img
                                src={showFile(item?.image)}
                                alt="img"
                                class="xl:w-[100px]"
                              />
                            </div>
                            <div class="pro-data w-full max-w-sm ">
                              <h5 class="font-medium text-[16px] leading-8 product-title0color  max-[550px]:text-center ">
                                {item?.name}
                              </h5>
                            </div>
                          </div>
                          <div class="flex items-center flex-col min-[550px]:flex-row w-full max-xl:max-w-xl max-xl:mx-auto gap-2 ">
                            <div class="flex items-center w-full mx-auto ">
                              <button
                                class="group mt-0 rounded-full w-[24px] bg-[#3F51B5] shadow-sm shadow-transparent hover:shadow-gray-200 hover:bg-[#3F51B5] text-white"
                                onClick={() => {
                                  decrementQuantity(item.id);
                                }}
                                disabled={item.quantity !== 1 ? false : true}
                              >
                               -
                              </button>
                              <div class="outline-none text-gray-900 font-semibold input-cart-bacground text-sm w-full max-w-[50px] text-[16px] placeholder:text-gray-900  text-center bg-transparent">
                                {item.quantity}
                              </div>
                              <div>
                              </div>
                              <button
                                class="group mt-0 rounded-full px-1 py-1 bg-[#3F51B5] flex items-center justify-center shadow-sm shadow-transparent hover:shadow-gray-200 hover:bg-[#3F51B5]"
                                onClick={() => {
                                  incrementQuantity(item.id);
                                }}
                              >
                                <IncrementBtn />
                              </button>
                            </div>
                            <h6 class="font-manrope font-semibold text-[16px] leading-9 cart-price-color w-full max-w-[176px] fonts-names-cart d-flex justify-center">
                              {item?.price}kr
                            </h6>
                            <button
                              onClick={() => deleteCartItem(item.id)}
                              class="font-manrope font-semibold text-[16px] leading-9 cart-price-color w-full max-w-[176px] fonts-names-cart "
                            >
                              <DeleteBtn />
                            </button>
                          </div>
                        </div>
                      </>
                    );
                  })}
                {availableCartItems.length === 0 &&
                  (
                    <div class="d-flex justify-center p-4">
                      <div class="text-[#666666]">Tomt i varukorgen</div>
                    </div>
                  )}
              </div>
              <div
                class={"flex justify-between items-center mb-3 border-t pt-3"}
              >
                {availableCartItems.length > 0 && (
                  <span class={"mb-0 mr-[28px]"}>
                    <span class="text-sm font-medium mr-1">Summa:</span>{" "}
                    <span class="text-sm">
                      {availableCartItems.reduce((total: any, num: any) => {
                        return Number(total) + Number(num.price);
                      }, 0)}kr
                    </span>
                  </span>
                )}
                {availableCartItems.length > 0 && (
                  <a
                    href={availableCartItems.length === 0 ? "#" : "/checkout"}
                    class="bg-[#3f51b5] rounded text-white px-2 py-2 mt-0 text-xs font-medium"
                    style={{ borderRadius: "50px", background: "#3F51B5" }}
                  >
                    Till kassan
                  </a>
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};
export default POP;

